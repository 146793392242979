import React from "react"
import { Link } from "gatsby"
import * as Icon from "react-feather"

import BlogImg1 from "../../assets/images/blog-image/blog1.jpg"
import BlogImg2 from "../../assets/images/blog-image/blog2.jpg"
import BlogImg3 from "../../assets/images/blog-image/blog3.jpg"

const BlogPost = () => {
  return (
    <div className="blog-area pt-80 pb-50">
      <div className="container">
        <div className="section-title">
          <h2>Our Fairy Tales</h2>
          <div className="bar"></div>
          <p>
            Discover inspiring stories where we helped our customers achieve
            their business goals through automation, making a significant
            impact. Explore to see how we can help you take your business to the
            next level.
          </p>
        </div>

        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6">
            <div className="single-blog-post">
              <div className="blog-image">
              {/*  <Link to="#">*/}
                  <img src={BlogImg1} alt="image" />
                {/*</Link>*/}

                <div className="date">
                  <Icon.Calendar /> Jan 11, 2023
                </div>
              </div>

              <div className="blog-post-content">
                <h3>
               {  /* <Link to="#">*/}
                    How SolidPro achieved dramatic gains in File management ?
                {/*}  </Link>*/}
                </h3>

                <span>
                  By <Link to="#">Admin</Link>
                </span>

                <p>
                  SolidPro reduced their burden in file management by automating
                  their document sync process with our intelligent automation
                  solutions which helped them save time and improve
                  productivity.
                </p>

{/*                <Link to="#" className="read-more-btn">
                  Read More <Icon.ArrowRight />
  </Link>*/}
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="single-blog-post">
              <div className="blog-image">
               {/* <Link to="#">*/}
                  <img src={BlogImg2} alt="image" />
              {/*  </Link>*/}

                <div className="date">
                  <Icon.Calendar /> Feb 04, 2023
                </div>
              </div>

              <div className="blog-post-content">
                <h3>
                 {/* <Link to="#">*/}
                    Transforming Ticketing Processes: Our Salesforce Success
                    Story
                 {/* </Link>*/}
                </h3>

                <span>
                  By <Link to="#">Admin</Link>
                </span>

                <p>
                  We streamlined the Salesforce ticketing process for our
                  customer, resulting in faster response times, improved
                  customer satisfaction, and increased sales productivity.
                </p>

             {/*   <Link to="#" className="read-more-btn">
                  Read More <Icon.ArrowRight />
</Link>*/}
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="single-blog-post">
              <div className="blog-image">
                {/*<Link to="#">*/}
                  <img src={BlogImg3} alt="image" />
               {/* </Link>*/}

                <div className="date">
                  <Icon.Calendar /> March 19, 2021
                </div>
              </div>

              <div className="blog-post-content">
                <h3>
                { /* <Link to="#">*/}
                    Streamlining GST Filing: Invoice Consolidation in Zoho CRM
                 {/* </Link>*/}
                </h3>

                <span>
                  By <Link to="#">Admin</Link>
                </span>

                <p>
                  We streamlined our customer's invoice consolidation process
                  for GST filing in Zoho CRM, resulting in a 50% reduction in
                  manual work and improved accuracy and efficiency.
                </p>

              {/*  <Link to="#" className="read-more-btn">
                  Read More <Icon.ArrowRight />
</Link>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlogPost
